import { Outlet } from "react-router-dom"
import { Box, Container } from "@mui/material"
import { AppHeader, Navigation } from "../components"
import { Footer } from "../components/footer"
import { useAuth } from "../hooks"
import { useMenu } from "../hooks/useMenu"

export const PrivateLayout = () => {
  const auth = useAuth()
  console.log(auth)
  const menu = useMenu({ role: auth?.role })
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
    >
      <AppHeader />
      <Navigation menu={menu}>
        <Container maxWidth={false}>
          <Box display="flex" justifyContent="center" height="100%">
            <Outlet />
          </Box>
        </Container>
      </Navigation>
      <Footer />
    </Box>
  )
}
