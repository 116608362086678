import { App } from "../interfaces";

export function getUserRole(roleName?: string): App.UserRole {
    switch (roleName) {
        case "Admin": return App.UserRole.ADMIN
        case "Operator": return App.UserRole.MANAGER
        case "TgManager": return App.UserRole.USER

        default: return App.UserRole.ANON
    }
}