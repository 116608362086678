import { MenuListItem } from "@/modules/app/components"
import { App } from "@/modules/app/interfaces"
import { List } from "@mui/material"

export const MarketplaceMenu = () => {
  return (
    <List disablePadding>
      <MenuListItem
        item={{ role: App.UserRole.MANAGER, url: "products", title: "Товары" }}
        depth={0}
        url="products"
      />
      <MenuListItem
        item={{
          role: App.UserRole.MANAGER,
          url: "product-categories",
          title: "Категории товаров",
        }}
        depth={0}
        url="product-categories"
      />
      <MenuListItem
        item={{
          role: App.UserRole.MANAGER,
          url: "product-payments",
          title: "Фасовки",
        }}
        depth={0}
        url="product-payments"
      />
      <MenuListItem
        item={{ role: App.UserRole.MANAGER, url: "cities", title: "Города" }}
        depth={0}
        url="cities"
      />
      <MenuListItem
        item={{ role: App.UserRole.MANAGER, url: "districts", title: "Районы" }}
        depth={0}
        url="districts"
      />
      <MenuListItem
        item={{
          role: App.UserRole.MANAGER,
          url: "couriers",
          title: "Сотрудники",
        }}
        depth={0}
        url="couriers"
      />
      <MenuListItem
        item={{
          role: App.UserRole.MANAGER,
          url: "questionnaire",
          title: "Анкеты",
        }}
        depth={0}
        url="questionnaire"
      />
      <MenuListItem
        item={{
          role: App.UserRole.MANAGER,
          url: "procurements",
          title: "Закупы",
        }}
        depth={0}
        url="procurements"
      />
    </List>
  )
}
