import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import { PrivateRoute } from "@app/components"
import { ErrorBoundary } from "@core/components"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { LoginPage, LogoutPage } from "@app/pages"
import { useAppSelector, useAppTheme, useDefaultAppThemeName } from "@app/hooks"
import { getAppThemeName } from "@app/store"
import { App as AppInterface } from "@app/interfaces"
import { LicenseInfo } from "@mui/x-data-grid-pro"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { ru } from "date-fns/locale"
import { ErrorPage } from "@core/pages"
import { LinkComparePage } from "./modules/tgstat/pages"
import { LigaPage } from "./modules/marketplace/pages"
import {
  MarketplaceCitiesTable,
  MarketplaceDistrictsTable,
  MarketplaceProductsTable,
} from "./modules/marketplace/components"
import { ProductCategoriesTable } from "./modules/marketplace/components/productCategories"
import { ProductPaymentCategorByProduct } from "./modules/marketplace/components/measure/productPaymentCategorByProduct"
import { HrCandidatePage } from "./modules/hr/pages"
import { StaffTable } from "./modules/marketplace/components/staffTable"
import { QuestionnaireTable } from "./modules/marketplace/components/questionnaireTable"
import { MetabaseEmbed } from "./modules/pr/components"
import { NcMembersPage, TelegramPage } from "./modules/telegram/pages"
import { Metabase } from "./modules/pr/interfaces"
import { ProcurementsTable } from "./modules/marketplace/components/procurementsTable"
import { MailingPage } from "./modules/telegram/pages/mailingPage"
import {
  OrdersPage,
  OrderPage,
  AgentsPage,
  TopUpPage,
  PaymentsPage,
} from "./modules/adv2/pages"
import { AgentPage } from "./modules/adv2/pages/agentPage"
import { PrivateLayout, PublicLayout } from "./modules/app/layout"
import { generateLicense } from "@mui/x-license-pro/generateLicense"
import { InviteLinkPage } from "./modules/tgstatBot/pages/inviteLInkPage"
import { MyChatsPage } from "./modules/tgstatBot/pages/myChatsPage"
import { ChatPage } from "./modules/tgstatBot/pages/chatPage"

const expiryDate = new Date()
expiryDate.setFullYear(new Date().getFullYear() + 1)
const licenseKey = generateLicense({
  orderNumber: "2654987",
  expiryDate: expiryDate,
  scope: "premium",
  licensingModel: "subscription",
})
LicenseInfo.setLicenseKey(licenseKey)

const getRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<PublicLayout />}>
          {/* anonimous */}
          <Route
            element={
              <PrivateRoute
                needRole={AppInterface.UserRole.ANON}
                exactRole
                redirectTo={"/"}
              />
            }
          >
            <Route index path="login" element={<LoginPage />} />
          </Route>
        </Route>

        {/* not anonimous */}
        <Route path="/" element={<PrivateLayout />}>
          <Route
            path="logout"
            element={
              <PrivateRoute
                element={<LogoutPage />}
                needRole={AppInterface.UserRole.USER}
                redirectTo={"/login"}
              />
            }
          />

          {/* user */}
          <Route
            element={
              <PrivateRoute
                needRole={AppInterface.UserRole.USER}
                redirectTo={"/login"}
                enableComeBackRedirect
              />
            }
          >
            <Route index element={<Navigate to={"telegram/chats"} replace />} />
            <Route path="telegram">
              <Route path="chats">
                <Route index element={<MyChatsPage />} />
                <Route path=":chatId">
                  <Route index element={<ChatPage />} />
                  <Route path="link" element={<InviteLinkPage />} />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* manager */}
          <Route
            element={
              <PrivateRoute
                needRole={AppInterface.UserRole.MANAGER}
                redirectTo={"/login"}
                enableComeBackRedirect
              />
            }
          >
            {/* home page */}
            <Route index element={<Navigate to={"marketplace"} replace />} />
            <Route path="marketplace">
              <Route element={<LigaPage />}>
                <Route index element={<Navigate to="products" />} />
                <Route path="products" element={<MarketplaceProductsTable />} />
                <Route
                  path="product-categories"
                  element={<ProductCategoriesTable />}
                />
                <Route path="cities" element={<MarketplaceCitiesTable />} />
                <Route
                  path="districts"
                  element={<MarketplaceDistrictsTable />}
                />
                <Route
                  path="product-payments"
                  element={<ProductPaymentCategorByProduct />}
                />
                <Route path="couriers" element={<StaffTable />} />
                <Route path="questionnaire" element={<QuestionnaireTable />} />
                <Route path="procurements" element={<ProcurementsTable />} />
              </Route>
              <Route
                path="emptytime"
                element={
                  <MetabaseEmbed
                    type={Metabase.EmbedType.dashboard}
                    id={14}
                    height="1650px"
                  />
                }
              />
              <Route
                path="reloading"
                element={
                  <MetabaseEmbed
                    type={Metabase.EmbedType.dashboard}
                    id={6}
                    height="1650px"
                  />
                }
              />
              <Route
                path="sales-statistic"
                element={
                  <MetabaseEmbed
                    type={Metabase.EmbedType.dashboard}
                    id={20}
                    height="1650px"
                  />
                }
              />
              <Route
                path="couriers-statistic"
                element={
                  <MetabaseEmbed
                    type={Metabase.EmbedType.dashboard}
                    id={15}
                    height="1650px"
                  />
                }
              />
            </Route>
            <Route path="telegram">
              <Route index element={<Navigate to="nc-members" />} />
              <Route path="nc-updates" element={<TelegramPage />} />
              <Route path="nc-members" element={<NcMembersPage />} />
              <Route path="mailings" element={<MailingPage />} />
              {/* <Route path="invite-links">
                <Route index element={<InviteLinksPage />} /> */}
              <Route path="chats">
                <Route index element={<MyChatsPage />} />
                <Route path=":chatId">
                  <Route index element={<ChatPage />} />
                  <Route path="link" element={<InviteLinkPage />} />
                </Route>
              </Route>
              {/* </Route> */}
              <Route path="tgstat">
                <Route index element={<LinkComparePage />} />
              </Route>
            </Route>
            <Route path="hr">
              <Route index element={<HrCandidatePage />} />
            </Route>
            <Route path="adv">
              <Route index element={<Navigate to="orders" />} />
              <Route index path="orders" element={<OrdersPage />} />
              <Route index path="orders/:orderId" element={<OrderPage />} />
              <Route path="agents/:agentId/:orderId" element={<OrderPage />} />
              <Route path="agents" element={<AgentsPage />} />
              <Route path="agents/:agentId" element={<AgentPage />} />
              <Route path="top-up" element={<TopUpPage />} />
              <Route path="payments" element={<PaymentsPage />} />
            </Route>
          </Route>
        </Route>

        <Route
          path="*"
          element={
            <ErrorPage
              code={404}
              text={"Запрашиваемой страницы не существует 🤷‍♂️"}
            />
          }
        />
      </>,
    ),
  )

function App() {
  const themeName = useAppSelector(getAppThemeName)
  const defaultThemeName = useDefaultAppThemeName()
  const theme = useAppTheme(themeName ?? defaultThemeName)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <ErrorBoundary>
          <RouterProvider router={getRouter()} />
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
