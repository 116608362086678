import { Box } from "@mui/material"
import { AppMenu } from "."
import { App } from "../interfaces"

export const Navigation = ({
  children,
  menu,
}: {
  children: React.ReactElement
  menu: App.RecursiveMenuItem[]
}) => {
  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <AppMenu menu={menu} />
      {children}
    </Box>
  )
}
