import { Navigate, Outlet, Path, To, useLocation } from "react-router-dom"
import { useAuth } from "@app/hooks"
import { ErrorPage } from "@core/pages"

interface IPrivateRouteProps {
  needRole: number
  exactRole?: boolean
  redirectTo?: To
  enableComeBackRedirect?: boolean
}
interface IPrivateRouteChildrenProps extends IPrivateRouteProps {
  children?: React.ReactElement
}
interface IPrivateRouteElementProps extends IPrivateRouteProps {
  element?: React.ReactElement
}
type PrivateRouteProps = IPrivateRouteChildrenProps | IPrivateRouteElementProps

export function PrivateRoute({
  redirectTo,
  needRole = 0,
  exactRole = false,
  enableComeBackRedirect,
  ...props
}: PrivateRouteProps) {
  const auth = useAuth()

  const { pathname, search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const comeBack = searchParams.get("redirectTo")

  if (needRole > auth.role || (exactRole && needRole !== auth.role)) {
    if (redirectTo) {
      let to: Partial<Path>
      if (typeof redirectTo === "string") {
        to = {
          pathname: redirectTo,
        }
      } else {
        to = {
          ...redirectTo,
        }
      }
      if (enableComeBackRedirect) {
        if (to.search) {
          to.search += `&redirectTo=${pathname}`
        } else {
          to.search = `redirectTo=${pathname}`
        }
      }
      return <Navigate to={comeBack ?? to} />
    }
    return (
      <ErrorPage code={403} text="Недостаточно прав для просмотра страницы" />
    )
  }

  const element = ("children" in props && props.children) ||
    ("element" in props && props.element) || <Outlet />
  return element
}
