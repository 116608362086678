import { FC } from "react"
import { RtkHookForm } from "@/core/components"
import { Grid, Stack, Typography } from "@mui/material"
import { TgstatBot } from "../interfaces"
import { useCreateInviteLinkMutation } from "../store"
import { DateTimePickerElement, SwitchElement, TextFieldElement } from "react-hook-form-mui"
import { useParams } from "react-router-dom"
import { format } from "date-fns"
import { MembersLimit } from "./formFields/membersLimit"

interface IProps {}
type IFormData = Omit<TgstatBot.CreateInviteLink, 'chat_id'> & {}

export const CreateInviteLinkForm: FC<IProps> = (props) => {
  const { chatId } = useParams()
  return (
    <RtkHookForm<
      IFormData,
      TgstatBot.CreateInviteLinkRes,
      typeof useCreateInviteLinkMutation
    >
      successMessage="Ссылка создана успешно"
      mutationHook={useCreateInviteLinkMutation}
      getSubmitHandlerParams={(data) => ({ body: {
        ...data,
        chat_id: chatId!,
      }})}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <TextFieldElement<IFormData> name="name" label="Имя" />
            <TextFieldElement<IFormData>
              name="comment"
              label="Комментарий"
              multiline
              rows={3}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <SwitchElement<IFormData> name="creates_join_request" label="Вступление по заявкам" />
            <MembersLimit<IFormData> />
            <DateTimePickerElement<IFormData>
              name="expire_date"
              label="Действительна до"
              disablePast
              helperText={
                <Typography variant="caption">
                  Часовой пояс: {format(Date.now(), "OOOO")}
                </Typography>
              }
            />
          </Stack>
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
