import { SvgIcon } from "@mui/material"

export namespace App {
  export interface UserAuthRequest {
    username: string
    password: string
  }
  export interface UserAuthResult {
    access_token: string
  }
  export interface UserAuth {
    authorized: boolean
    exp: number
    role_name: string
    user_id: number
  }

  export interface MenuItem {
    title: string
    url: string
    role: UserRole
    exactRole?: boolean
    icon?: typeof SvgIcon
    isOpen?: boolean
  }

  export interface MenuGroup extends Omit<MenuItem, "url"> {
    items: RecursiveMenuItem[]
  }

  export type RecursiveMenuItem = MenuItem | MenuGroup

  export enum UserRole {
    ANON,
    USER,
    MANAGER,
    ADMIN,
  }
  export interface User {
    role: UserRole
    token?: string
  }

  export interface RouterState {
    backgroundLocation?: Location
  }

  export enum Gender {
    MAN = "Мужской",
    WOMAN = "Женский",
  }
}
