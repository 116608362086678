import { FC } from "react"
import { Container } from "@mui/material"
import { useTitle } from "@/core/hooks"
import { MyChatsTable } from "../components/myChatsTable"

interface IProps {}

export const MyChatsPage: FC<IProps> = (props) => {
  useTitle(`PR ADV - Чаты`)

  return (
    <Container maxWidth={false}>
      <MyChatsTable />
    </Container>
  )
}
