import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  RootState,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@core/store"
import { baseQueryWithReauth } from "@/modules/app/store"
import { TgstatBot } from "../interfaces"

const tagTypes = ["InviteLinks", "InviteLink", "MyChats", "ActiveBot"] as const

const reducerPath: string = "tgstatBotApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_TGSTAT_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
  }`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const endpoints = (build: ApiEndpointBuider) => ({
  getActiveBot: build.query<
    TgstatBot.GetActiveBotRes,
    TgstatBot.GetActiveBotReq
  >({
    query: () => ({
      url: `tgstat-bot/active-bot`,
      method: "GET",
    }),
    providesTags: ["ActiveBot"],
  }),
  getInviteLinks: build.query<
    TgstatBot.GetInviteLinksRes,
    TgstatBot.GetInviteLinksReq
  >({
    query: ({ queryParams }) => ({
      url: `tgstat-bot/invite-links`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["InviteLinks"],
  }),
  getInviteLink: build.query<
    TgstatBot.GetInviteLinkRes,
    TgstatBot.GetInviteLinkReq
  >({
    query: ({ queryParams }) => ({
      url: `tgstat-bot/invite-link`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["InviteLink"],
  }),
  getChat: build.query<
    TgstatBot.GetChatRes,
    TgstatBot.GetChatReq
  >({
    query: ({ queryParams }) => ({
      url: `tgstat-bot/get-chat`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["InviteLink"],
  }),
  getMyChats: build.query<TgstatBot.GetMyChatsRes, TgstatBot.GetMyChatsReq>({
    query: ({ queryParams }) => ({
      url: `tgstat-bot/my-chats`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["MyChats"],
  }),
  createInviteLink: build.mutation<TgstatBot.CreateInviteLinkRes, TgstatBot.CreateInviteLinkReq>({
    query: ({ body }) => {
      return {
        url: `tgstat-bot/invite-link`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["InviteLinks"],
  }),
  revokeInviteLink: build.mutation<TgstatBot.RevokeInviteLinkRes, TgstatBot.RevokeInviteLinkReq>({
    query: ({ body }) => {
      return {
        url: `tgstat-bot/invite-link/revoke`,
        method: "PATCH",
        body,
      }
    },
    invalidatesTags: ["InviteLinks"],
  }),
})

export const prApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...endpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(prApi.middleware)
registerReducer(prApi.reducerPath, prApi.reducer)

export const {
  useGetChatQuery,
  useGetActiveBotQuery,
  useGetInviteLinkQuery,
  useGetInviteLinksQuery,
  useGetMyChatsQuery,
  useCreateInviteLinkMutation,
  useRevokeInviteLinkMutation,
} = prApi
