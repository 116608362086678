import { FC, useEffect, useState } from "react"
import {
  AutocompleteElement,
  CheckboxElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui"
import { IFormProps } from "@/core/interfaces"
import { Hr } from "../interfaces"
import {
  useCreateCandidateMutation,
  useGetCandidateSourcesQuery,
} from "../store"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material"
import { ListboxComponent, SnackbarAlert } from "@/core/components"
import { useGetLocationsQuery } from "@/modules/marketplace/store"
import { Liga } from "@/modules/marketplace/interfaces"
import { App } from "@/modules/app/interfaces"

interface IProps extends IFormProps<Hr.CreateCandidateRes> {}
interface IFormData extends Omit<Hr.Candidate, "id" | "date_create" | "sent"> {}

export const HrCandidateForm: FC<IProps> = (props) => {
  const [createHrCandidate, { isLoading, isError, isSuccess, error }] =
    useCreateCandidateMutation()

  const { data: locations, isFetching: isLocationsLoading } =
    useGetLocationsQuery({
      queryParams: {
        type: "Город",
      },
    })
  const { data: sources, isFetching: isSourcesLoading } =
    useGetCandidateSourcesQuery({})

  const [isSnackbarOpen, setSnackbarOpen] = useState(isError)
  useEffect(() => {
    setSnackbarOpen(isError || isSuccess)
  }, [isError, isSuccess])

  const formContext = useForm<IFormData>({
    defaultValues: {
      has_car: false,
      has_documents: false,
    },
  })
  const watch = formContext.watch()

  const getBodyValues = (data: IFormData): Hr.CreateCandidatesReq["body"] => ({
    city: data.city,
    age: data.age,
    deposit: data.deposit,
    source: data.source,
    description: data.description,
    matrix_id: data.matrix_id,
    telegram_id: data.telegram_id,
    gender: data.gender,
    has_car: data.has_car,
    has_documents: data.has_documents,
    bot_username: data.bot_username,
    referral: data.referral,
    result: data.result,
    chat_url: data.chat_url,
  })
  const createCandidate = (data: IFormData) => {
    createHrCandidate({
      body: getBodyValues(data),
    })
      .unwrap()
      .then(() => {
        formContext.reset()
        // formContext.setValue("city", undefined)
        // formContext.setValue("source", undefined)
      })
  }
  useEffect(() => {
    formContext.unregister("deposit")
  }, [watch.has_documents])

  return (
    <FormContainer<IFormData>
      onSuccess={createCandidate}
      formContext={formContext}
    >
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant={isError ? "error" : "success"}
        title={isError ? "Произошла ошибка" : "Успешно"}
        message={isError ? JSON.stringify(error) : "Кандидат сохранён"}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} xl={6}>
              <AutocompleteElement
                name="city"
                label="Город"
                loading={isLocationsLoading}
                required
                autocompleteProps={{
                  getOptionLabel: (option: Liga.Location["name"]) =>
                    option ? `${option}` : "Загрузка...",
                  ListboxComponent: ListboxComponent,
                  renderOption: (props, option, state) =>
                    [props, `${option}`, state] as React.ReactNode,
                  freeSolo: true,
                  autoSelect: true,
                  fullWidth: true,
                }}
                options={locations?.map((l) => l.name) ?? []}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <TextFieldElement
                label="Возраст"
                name="age"
                required
                type="number"
                fullWidth
                inputProps={{
                  step: 1,
                  min: 0,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <AutocompleteElement
                name="gender"
                label="Пол"
                autocompleteProps={{
                  fullWidth: true,
                }}
                options={Object.values(App.Gender)}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <AutocompleteElement
                name="source"
                label="Откуда узнал (cо слов кандидата)"
                loading={isSourcesLoading}
                required
                autocompleteProps={{
                  getOptionLabel: (option: string) =>
                    option ? `${option}` : "Загрузка...",
                  ListboxComponent: ListboxComponent,
                  renderOption: (props, option, state) =>
                    [props, `${option}`, state] as React.ReactNode,
                  freeSolo: true,
                  autoSelect: true,
                  fullWidth: true,
                }}
                options={sources ?? []}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <TextFieldElement
                label="Написал в бот"
                name="bot_username"
                placeholder="@worka"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <TextFieldElement
                label="Matrix ID"
                name="matrix_id"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <TextFieldElement label="Кто привёл" name="referral" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <TextFieldElement
                label="Telegram ID"
                name="telegram_id"
                required
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              xl={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CheckboxElement label="Есть авто" name="has_car" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              xl={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CheckboxElement label="По документам" name="has_documents" />
            </Grid>
            <Grid item xs={12} sm={3} xl={3}>
              <TextFieldElement
                label="Депозит"
                name="deposit"
                required={!watch.has_documents}
                type="number"
                fullWidth
                inputProps={{
                  step: 1000,
                  min: 0,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <TextFieldElement
                label="Ссылка на чат"
                name="chat_url"
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TextFieldElement
              label="Описание"
              name="description"
              required
              multiline
              rows={8}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider light sx={{ marginY: 2 }} />
      <Box display="flex" justifyContent="end">
        <Button disabled={isLoading} type="submit" variant="contained">
          Сохранить&nbsp;{isLoading && <CircularProgress size={20} />}
        </Button>
      </Box>
    </FormContainer>
  )
}
