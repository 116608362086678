import { FC } from "react"
import { DataGrid, PreviousLocation } from "@/core/components"
import {
  GridActionsCellItem,
  gridClasses,
  GridColDef,
} from "@mui/x-data-grid-pro"
import { GRID_PAGE_SIZE_OPTIONS } from "@/core/constants"
import { useAppDispatch, useAppSelector } from "@/modules/app/hooks"
import { Preview } from "@mui/icons-material"
import { useNavigate } from "@/core/hooks"
import { getTgstatBotState, setMyChatsPaginationModel, useGetActiveBotQuery, useGetMyChatsQuery } from "../store"
import { TgstatBot } from "../interfaces"
import { formatDateAndTime } from "@/core/utils"

interface IProps {}
export const MyChatsTable: FC<IProps> = (props) => {
  const navigate = useNavigate<PreviousLocation>()
  const dispatch = useAppDispatch()
  const {
    inviteLinks: { page, pageSize },
  } = useAppSelector(getTgstatBotState)
  const { isFetching, data: dataAndTotal } = useGetMyChatsQuery({
    queryParams: {
      page: page + 1,
      page_size: pageSize,
    },
  })
  const [data, totalCount] = dataAndTotal ?? [];
  const { data: activeBot } = useGetActiveBotQuery({})

  const columns: GridColDef<TgstatBot.Chat>[] = [
    {
      headerName: "Название",
      field: "chat_title",
      flex: 2,
    },
    {
      headerName: "Юзернейм",
      field: "chat_username",
      flex: 2,
    },
    {
      headerName: "Статус",
      field: "status",
      flex: 1,
    },
    {
      headerName: "Может создавать ссылки",
      field: "can_invite_users",
      flex: 1,
      type: 'boolean',
    },
    {
      headerName: "Дата добавления",
      field: "created_at",
      flex: 1,
      valueGetter: ({value}) => formatDateAndTime(value),
    },
  ]

  return (
    <DataGrid<TgstatBot.Chat>
      columns={columns}
      rows={data ?? []}
      getRowId={(row) => row.chat_id}
      // getRowId={(row) => `${row.chat_id}_${row.bot_id}`}
      rowCount={totalCount ?? 0}
      loading={isFetching}
      pagination
      paginationMode="server"
      pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
      onPaginationModelChange={(model) =>
        dispatch(setMyChatsPaginationModel(model))
      }
      initialState={{
        pagination: { paginationModel: { page, pageSize } },
      }}
      getRowClassName={(params) => params.row.bot_id !== activeBot?.id ? `${gridClasses.row}--disabled` : ""}
      rowActions={[
        (r) => (
          <GridActionsCellItem
            label="Открыть"
            icon={<Preview />}
            onClick={() =>
              navigate({pathname: r.id.toString()}, {
                state: {
                  from: window.location.pathname,
                  title: "К списку чатов",
                },
              })
            }
          />
        ),
      ]}
    />
  )
}
