import { FC } from "react"
import { Container } from "@mui/material"
import { useTitle } from "@/core/hooks"

interface IProps {}

export const InviteLinkPage: FC<IProps> = (props) => {
  useTitle(`PR ADV - Пригласительная ссылка`)

  return (
    <Container maxWidth={false}>
    </Container>
  )
}
