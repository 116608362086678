import { FieldValues, TextFieldElement, useFormContext } from "react-hook-form-mui"
import { TgstatBot } from "../../interfaces"

type IField = Pick<
  TgstatBot.CreateInviteLink,
  "member_limit" | "creates_join_request"
>

export function MembersLimit<T extends FieldValues = IField>(): JSX.Element {
  const ctx = useFormContext<IField>()
  const createsJoinRequest = ctx?.watch("creates_join_request")

  return (
    <TextFieldElement<IField> name="member_limit" label="Лимит вступлений" type="number" disabled={createsJoinRequest} />
  )
}
