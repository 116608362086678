import { App } from "../interfaces";
import { menu } from "../constants";
import { useCallback, useMemo } from "react";

interface IParams {
  role: App.UserRole;
}

function filterByRole(menu: App.RecursiveMenuItem[], role: App.UserRole): App.RecursiveMenuItem[] {
  return menu.reduce<App.RecursiveMenuItem[]>(((res, item) => {
    if (item.role > role || (item.exactRole && item.role !== role) ) {
      return res;
    }
    const newItem = 'items' in item ? {
      ...item,
      items: filterByRole(item.items, role),
    } : item
    res.push(newItem)
    return res;
  }), [])
}

export const useMenu = ({role}: IParams): App.RecursiveMenuItem[] => {
  return useMemo(() => filterByRole(menu, role), [role])
}
