import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, StateFromRoot, registerReducer, store } from "@core/store"
import { GridPaginationModel } from "@mui/x-data-grid-pro"
import { GRID_PAGE_SIZE_OPTIONS } from "@/core/constants"

const STATE_KEY = "tgstatBot"
export const CONFIG_KEY = `${STATE_KEY}_state`

type TableName = "inviteLinks" | "myChats"
const defaultTableState: GridPaginationModel = {
  page: 0,
  pageSize: GRID_PAGE_SIZE_OPTIONS[2],
}
type IState = {
  [table in TableName]: GridPaginationModel
}

const persistantState = localStorage.getItem(CONFIG_KEY)
const emptyState: IState = {
  inviteLinks: defaultTableState,
  myChats: defaultTableState,
}

const initialState: IState = persistantState
  ? {...emptyState, ...JSON.parse(persistantState)}
  : emptyState

export const tgstatBotSlice = createSlice({
  name: "tgstatBot",
  initialState: initialState,
  reducers: {
    setInviteLinksPaginationModel: (
      state,
      { payload: model }: PayloadAction<GridPaginationModel>,
    ) => {
      state.inviteLinks = model
    },
    setMyChatsPaginationModel: (
      state,
      { payload: model }: PayloadAction<GridPaginationModel>,
    ) => {
      state.inviteLinks = model
    },
  },
})
registerReducer(tgstatBotSlice.name, tgstatBotSlice.reducer)
store.subscribe(() => {
  localStorage.setItem(CONFIG_KEY, JSON.stringify(store.getState()[STATE_KEY]))
})

export const { setInviteLinksPaginationModel, setMyChatsPaginationModel } = tgstatBotSlice.actions

export const getTgstatBotState = (state: RootState<StateFromRoot<IState>>) =>
  state[STATE_KEY]
